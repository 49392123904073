/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Amplify from 'aws-amplify'
import Header from "./header"
import { isLoggedIn } from "../utils/auth"
import { navigate } from "../utils/misc"
import "./layout.css"

const Layout = ({ children, hideHeader, fsOnly }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  let defaultStyle = {
    margin: `0 auto`,
    maxWidth: 1060,
    width: `100%`,
    padding: `0 1.0875rem 1.45rem`,
  }
  if(hideHeader){
    defaultStyle = {

    }
  }
  const initFSOnly = async () => {
    try {
      let user = await isLoggedIn()
      if (!user?.username?.includes("forms-surfaces.com")) {
        navigate("/403")
      }
    } catch (err) {
      navigate("/403")
      console.log(err)
    }
  }
  if(fsOnly){
    initFSOnly()
  }

  return (
    <>
      {!hideHeader &&
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      }
      <div
        style={defaultStyle}
      >
        <main>{children}</main>
        <footer style={{
          marginTop: `2rem`
        }}>

        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: process.env.GATSBY_COGNITO_REGION,
    userPoolId: process.env.GATSBY_USER_POOL_ID,
    identityPoolId: process.env.GATSBY_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_APP_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  }
})

export default Layout
